<template>
  <vDialog :show.sync="visible" width="80%" :needCloseButton="false" top="5%" class="tradingdialog">
    <div class="headerText" slot="title">{{ $t('ibAccounts.tradingList') }}</div>
    <div class="table_container">
      <div name="openListTable" class="tradingListTable">
        <el-table
          ref="openListTable"
          style="width: 100%"
          :data="openListTable"
          :row-key="openListTable != undefined ? openListTable.ticket : ' '"
        >
          <el-table-column :label="$t('ibAccounts.OpeningList')">
            <el-table-column prop="ticket" :label="$t('ibAccounts.tradingListColumn.ticket')" min-width="100">
            </el-table-column>
            <el-table-column prop="openTime" :label="$t('ibAccounts.tradingListColumn.openTime')" min-width="140">
            </el-table-column>
            <el-table-column prop="type" :label="$t('ibAccounts.tradingListColumn.type')" min-width="100">
              <template slot-scope="traderTableScope">
                {{
                  traderTableScope.row.type === 0
                    ? $t('ibAccounts.tradingListColumn.buy')
                    : $t('ibAccounts.tradingListColumn.sell')
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="volume"
              :label="$t('ibAccounts.tradingListColumn.volume')"
              min-width="100"
            ></el-table-column>
            <el-table-column
              prop="item"
              :label="$t('ibAccounts.tradingListColumn.item')"
              column-key="item"
              min-width="120"
            >
            </el-table-column>
            <el-table-column
              prop="openPrice"
              :label="$t('ibAccounts.tradingListColumn.openPrice')"
              column-key="openPrice"
              min-width="100"
            >
            </el-table-column>
            <el-table-column prop="closePrice" :label="$t('ibAccounts.tradingListColumn.closePrice')" min-width="100">
            </el-table-column>
            <el-table-column prop="commission" :label="$t('ibAccounts.tradingListColumn.commission')" min-width="100">
            </el-table-column>
            <el-table-column prop="profit" :label="$t('ibAccounts.tradingListColumn.profit')" min-width="100">
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="clearfix trpagin">
          <el-pagination
            :background="true"
            :page-size="pageSize"
            layout="total, sizes, prev ,pager, next"
            :total="openListData.length"
            :current-page="openTableCurrentPage"
            :page-sizes="pageSizes"
            @current-change="handleOpenTableChange"
            @size-change="handleOpenTableSizeChange"
          ></el-pagination>
        </div>
      </div>
      <div class="calendar_content_main">
        <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate"></DateRangePicker>
        <loading-button class="btn_red" :callback="searchClosedList" text="common.keys.UPDATE"></loading-button>
      </div>
      <div name="closedListTable" class="tradingListTable">
        <el-table
          ref="closedListTable"
          style="width: 100%"
          :data="closedListTable"
          :row-key="closedListTable != undefined ? closedListTable.ticket : ' '"
        >
          <el-table-column :label="$t('ibAccounts.closeList')">
            <el-table-column prop="ticket" :label="$t('ibAccounts.tradingListColumn.ticket')" min-width="100">
            </el-table-column>
            <el-table-column prop="openTime" :label="$t('ibAccounts.tradingListColumn.openTime')" min-width="140">
            </el-table-column>
            <el-table-column prop="type" :label="$t('ibAccounts.tradingListColumn.type')" min-width="100">
              <template slot-scope="traderTableScope">
                {{
                  traderTableScope.row.type === 0
                    ? $t('ibAccounts.tradingListColumn.buy')
                    : $t('ibAccounts.tradingListColumn.sell')
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="volume"
              :label="$t('ibAccounts.tradingListColumn.volume')"
              min-width="100"
            ></el-table-column>
            <el-table-column
              prop="item"
              :label="$t('ibAccounts.tradingListColumn.item')"
              column-key="item"
              min-width="120"
            >
            </el-table-column>
            <el-table-column
              prop="openPrice"
              :label="$t('ibAccounts.tradingListColumn.openPrice')"
              column-key="openPrice"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="closeTime"
              :label="$t('ibAccounts.tradingListColumn.closeTime')"
              column-key="closeTime"
              min-width="140"
            ></el-table-column>
            <el-table-column prop="closePrice" :label="$t('ibAccounts.tradingListColumn.closePrice')" min-width="100">
            </el-table-column>
            <el-table-column prop="commission" :label="$t('ibAccounts.tradingListColumn.commission')" min-width="100">
            </el-table-column>
            <el-table-column prop="profit" :label="$t('ibAccounts.tradingListColumn.profit')" min-width="100">
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="trpagin">
          <el-pagination
            :background="true"
            :page-size="pageSize"
            layout="total, sizes, prev, pager , next"
            :total="closedListData.length"
            :current-page="closedTableCurrentPage"
            :page-sizes="pageSizes"
            @current-change="handleClosedTableChange"
            @size-change="handleClosedTableSizeChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </vDialog>
</template>

<script>
import vDialog from '@/components/vDialog.vue';
import LoadingButton from '@/components/LoadingButton';
import DateRangePicker from '@/components/DateRangePicker';
import { apiGetOpenList, apiGetClosedList } from '@/resource';

export default {
  props: {
    tradingListVisible: Boolean,
    accountNumber: Number
  },
  components: { vDialog, LoadingButton, DateRangePicker },
  data() {
    return {
      visible: false,
      pageSize: 10,
      pageSizes: [10, 25],
      openTableCurrentPage: 1,
      closedTableCurrentPage: 1,
      startDate: '',
      endDate: '',
      openListData: [],
      openListTable: [],
      closedListData: [],
      closedListTable: []
    };
  },
  watch: {
    tradingListVisible(bool) {
      this.visible = bool;
      if (this.accountNumber != 0 && this.visible) this.searchOpenList();
    },

    visible(bool) {
      if (!bool) {
        this.closedListData = [];
        this.closedListTable = [];
        this.updateClosedTableData();
      }
      this.disableTradingListVisible(bool);
    }
  },
  methods: {
    disableTradingListVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:tradingListVisible', bool);
      });
    },
    searchClosedList() {
      this.getClosedList().then(resp => {
        if (resp.data.code === 0 && resp.data.data.length >= 0) {
          this.closedListData = resp.data.data;

          this.updateClosedTableData();
        }
      });
      return Promise.resolve();
    },
    searchOpenList() {
      this.getOpenList().then(resp => {
        if (resp.data.code === 0 && resp.data.data.length >= 0) {
          this.openListData = resp.data.data;

          this.updateOpenTableData();
        }
      });
    },
    getOpenList() {
      return apiGetOpenList({ account: this.accountNumber });
    },
    getClosedList() {
      return apiGetClosedList({
        account: this.accountNumber,

        startDateStr: this.startDate.split(' ')[0],

        endDateStr: this.endDate.split(' ')[0]
      });
    },
    handleOpenTableChange(pageNumber) {
      this.openTableCurrentPage = pageNumber;

      this.updateOpenTableData();
    },
    handleOpenTableSizeChange(size) {
      this.pageSize = size;

      this.updateOpenTableData();
    },
    handleClosedTableChange(pageNumber) {
      this.closedTableCurrentPage = pageNumber;

      this.updateClosedTableData();
    },
    handleClosedTableSizeChange(size) {
      this.pageSize = size;

      this.updateClosedTableData();
    },
    updateOpenTableData() {
      this.openListTable = this.openListData.slice(
        (this.openTableCurrentPage - 1) * this.pageSize,

        this.openTableCurrentPage * this.pageSize
      );
    },
    updateClosedTableData() {
      this.closedListTable = this.closedListData.slice(
        (this.closedTableCurrentPage - 1) * this.pageSize,

        this.closedTableCurrentPage * this.pageSize
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
