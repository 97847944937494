<template>
  <div id="retail_accounts">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="title">{{ $t('menu.allRetailAccs') }}</div>
        <div class="retail_content">
          <el-form :model="searchForm" ref="searchForm">
            <ul class="retail_search">
              <li>
                <el-form-item prop="type" class="searchField type">
                  <el-select v-model="searchForm.type" id="type" filterable @change="onTypeChange">
                    <el-option
                      v-for="(value, name) in typeList"
                      :key="value"
                      :value="name"
                      :label="value"
                      :data-testid="value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-form-item prop="content" class="searchField content">
                  <el-input
                    v-model="searchForm.content"
                    id="content"
                    auto-complete="new-password"
                    :placeholder="$t('allRetailAccs.placeholder')"
                    :maxlength="256"
                  />
                </el-form-item>
              </li>
              <li>
                <el-form-item prop="category" class="searchField category">
                  <el-select v-model="searchForm.category" id="category" filterable>
                    <el-option
                      v-for="(value, name) in categoryList"
                      :key="value"
                      :value="name"
                      :label="value"
                      :data-testid="value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </li>
              <li>
                <el-button @click="getAllRetailAccs()" data-testid="submit" icon="el-icon-search" class="searchbtn">
                  {{ $t('allRetailAccs.search') }}
                </el-button>
              </li>
            </ul>
            <ul class="table_content clearfix">
              <li class="active">
                <div class="table_box">
                  <el-table
                    ref="retailAccsTable"
                    :data="displayData"
                    style="width: 100%"
                    :fit="true"
                    class="retailAccsTable"
                  >
                    <el-table-column prop="date" :label="$t('common.keys.DATE')" min-width="100">
                      <template slot-scope="tableScope">
                        {{ tableScope.row.date | date('DD/MM/YYYY') }}
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.keys.ACCNUM')" min-width="100">
                      <template slot-scope="tableScope">
                        {{ tableScope.row.accountNumber }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('common.keys.NAME')" min-width="120"></el-table-column>
                    <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="120"></el-table-column>
                    <el-table-column prop="phone" :label="$t('common.keys.MOBILE')" min-width="120"></el-table-column>
                    <el-table-column
                      prop="platform"
                      :label="$t('common.keys.PLATFORM')"
                      column-key="platform"
                      min-width="100"
                    >
                      <template slot-scope="tableScope">{{ 'MT' + tableScope.row.platform }}</template>
                    </el-table-column>
                    <el-table-column
                      prop="baseCurrency"
                      :label="$t('common.keys.BASECURRENCY')"
                      min-width="100"
                    ></el-table-column>
                    <el-table-column prop="accountBalance" :label="$t('common.keys.BALANCE')" min-width="100">
                      <template slot-scope="tableScope">
                        {{ tableScope.row.accountBalance | currency('') }}
                      </template>
                    </el-table-column>
                    <!-- Profit Lost -->
                    <el-table-column
                      prop="profit"
                      :label="$t('common.keys.PROFITLOST')"
                      min-width="120"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <template slot-scope="tableScope">
                        {{ tableScope.row.profit | currency('') }}
                      </template>
                    </el-table-column>
                    <!-- Margin Level -->
                    <el-table-column
                      prop="marginLevel"
                      :label="$t('common.keys.MARGINLEVEL')"
                      min-width="120"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <template slot-scope="tableScope">
                        {{ tableScope.row.marginLevel | currency('') }}
                      </template>
                    </el-table-column>
                    <!-- Account Equity -->
                    <el-table-column
                      prop="equity"
                      :label="$t('common.keys.ACCEQUITY')"
                      min-width="120"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <template slot-scope="tableScope">
                        {{ tableScope.row.equity | currency('') }}
                      </template>
                    </el-table-column>
                    <!-- Credit -->
                    <el-table-column
                      prop="credit"
                      :label="$t('common.keys.CREDIT')"
                      min-width="100"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <template slot-scope="tableScope">
                        {{ tableScope.row.credit | currency('') }}
                      </template>
                    </el-table-column>
                    <!-- View -->
                    <el-table-column
                      prop="view"
                      :label="$t('common.keys.VIEW')"
                      min-width="100"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <template slot-scope="tableScope">
                        <el-dropdown :name="tableScope.row.accountNumber" trigger="click">
                          <span class="el-dropdown-link">
                            <i class="el-icon-more"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="showDetail(tableScope.row.accountNumber, 'trading')">{{
                              $t('allRetailAccs.tradeHistory')
                            }}</el-dropdown-item>
                            <el-dropdown-item @click.native="showDetail(tableScope.row.accountNumber, 'transaction')">{{
                              $t('allRetailAccs.depwith')
                            }}</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 分页 -->
                <div class="page_box clearfix">
                  <pagination :tableData="tableRetailAccs" :display-data.sync="displayData"></pagination>
                </div>
              </li>
            </ul>
          </el-form>
        </div>
      </div>
      <TradingList :tradingListVisible.sync="tradingListVisible" :accountNumber="accountDetails"></TradingList>
      <TransactionList
        :transactionListVisible.sync="transactionListVisible"
        :accountNumber="accountDetails"
      ></TransactionList>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { apiRetailAccs } from '@/resource';
import TradingList from '@/views/TradingList';
import TransactionList from '@/views/TransactionList';

export default {
  name: 'AllRetailAccounts',
  components: { Pagination, TradingList, TransactionList },
  data() {
    return {
      tableData: [],
      tableRetailAccs: [],
      displayData: [],
      pageNo: 1,
      userId: '',
      tableIndex: 0,
      categoryList: {
        0: this.$t('allRetailAccs.type.all'),
        1: this.$t('allRetailAccs.type.funded'),
        2: this.$t('allRetailAccs.type.unfunded')
      },
      typeList: {
        0: this.$t('common.keys.NONE'),
        1: this.$t('allRetailAccs.filter.account'),
        2: this.$t('allRetailAccs.filter.name'),
        3: this.$t('allRetailAccs.filter.email'),
        4: this.$t('common.keys.MOBILE')
      },
      searchForm: {
        type: '0',
        category: '0',
        content: ''
      },
      tradingListVisible: false,
      transactionListVisible: false,
      accountDetails: 0
    };
  },
  methods: {
    onTypeChange() {
      this.searchForm.content = '';
    },
    showDetail(account, list) {
      this.accountDetails = account;

      switch (list) {
        case 'trading':
          this.tradingListVisible = true;
          break;
        case 'transaction':
          this.transactionListVisible = true;
          break;
      }
    },
    getAllRetailAccs() {
      return apiRetailAccs({
        searchType: this.searchForm.type,
        searchContent: this.searchForm.content,
        category: this.searchForm.category
      }).then(resp => {
        if (resp.data.code === 0 && resp.data.data != null) {
          this.tableRetailAccs = resp.data.data;
        } else this.tableRetailAccs = [];
      });
    }
  },
  mounted() {
    this.userId = this.$store.state.common.CUID;
    this.getAllRetailAccs();
  }
};
</script>

<style lang="scss" scoped>
@import './style.scss';

.retailAccsTable {
  /deep/ th {
    text-transform: uppercase;
  }
}
</style>
