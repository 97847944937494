<template>
  <vDialog :show.sync="visible" width="80%" :needCloseButton="false" top="5%" class="transactiondialog">
    <div class="headerText" slot="title">{{ $t('allRetailAccs.depwith') }}</div>
    <div class="headerText headerSummary" slot="title">{{ $t('allRetailAccs.summary') }}</div>
    <div class="table_container">
      <div>
        <ul class="table_content clearfix">
          <li class="active">
            <div class="table_box">
              <el-table ref="summaryTable" :data="tableSummary" style="width: 100%" :fit="true" class="table_summary">
                <el-table-column prop="currency" :label="$t('allRetailAccs.currency')" min-width="120">
                </el-table-column>
                <el-table-column prop="totalDeposit" :label="$t('allRetailAccs.totalDeposit')" min-width="120">
                  <template slot-scope="tableScope">
                    {{ tableScope.row.totalDeposit | currency('') }}
                  </template>
                </el-table-column>
                <el-table-column prop="totalWithdraw" :label="$t('allRetailAccs.totalWithdrawal')" min-width="120">
                  <template slot-scope="tableScope">
                    {{ tableScope.row.totalWithdraw | currency('') }}
                  </template>
                </el-table-column>
                <el-table-column prop="netDeposit" :label="$t('allRetailAccs.netDeposit')" min-width="120">
                  <template slot-scope="tableScope">
                    {{ tableScope.row.netDeposit | currency('') }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <el-form :model="searchForm" ref="searchForm">
          <ul class="searchForm">
            <li>
              <el-date-picker
                v-model="searchForm.startDate"
                format="dd/MM/yyyy"
                type="date"
                :clearable="false"
                :picker-options="dateOptions"
                class="searchField date"
              >
              </el-date-picker>
            </li>
            <li>
              <el-form-item prop="type" class="searchField type">
                <el-select v-model="searchForm.type" id="type" filterable @change="onTypeChange">
                  <el-option
                    v-for="(value, name) in typeList"
                    :key="value"
                    :value="name"
                    :label="value"
                    :data-testid="value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </li>
            <li>
              <el-button class="blue_button" @click="getTransactionList()" data-testid="submit" icon="el-icon-search">
                {{ $t('allRetailAccs.search') }}
              </el-button>
            </li>
          </ul>
          <ul class="table_content clearfix">
            <li class="active">
              <div class="table_box">
                <el-table ref="detailsTable" :data="displayDetails" style="width: 100%" :fit="true">
                  <el-table-column prop="displayDate" :label="$t('common.keys.DATE')" min-width="120"></el-table-column>
                  <el-table-column
                    prop="paymentType"
                    :label="$t('ibAccounts.tradingListColumn.type')"
                    min-width="120"
                  ></el-table-column>
                  <el-table-column prop="amount" :label="$t('common.keys.amount')" min-width="120">
                    <template slot-scope="tableScope">
                      {{ tableScope.row.amount | currency('') }}
                    </template></el-table-column
                  >
                  <el-table-column
                    prop="currency"
                    :label="$t('allRetailAccs.currency')"
                    min-width="120"
                  ></el-table-column>
                </el-table>
              </div>
              <!-- 分页 -->
              <div class="clearfix trpagin">
                <el-pagination
                  small
                  :background="true"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager , next"
                  :total="tableDetails.length"
                  :current-page="DetailTableCurrentPage"
                  :page-sizes="pageSizes"
                  @current-change="handleDetailTableChange"
                  @size-change="handleDetailTableSizeChange"
                ></el-pagination>
              </div>
            </li>
          </ul>
        </el-form>
      </div>
    </div>
  </vDialog>
</template>

<script>
import vDialog from '@/components/vDialog.vue';
import Pagination from '@/components/Pagination';
import LoadingButton from '@/components/LoadingButton';
import DateRangePicker from '@/components/DateRangePicker';
import { apiTransactionHistory } from '@/resource';

export default {
  props: {
    transactionListVisible: Boolean,
    accountNumber: Number
  },
  components: { vDialog, LoadingButton, DateRangePicker, Pagination },
  data() {
    return {
      dateOptions: {
        disabledDate(date) {
          return date > new Date();
        }
      },
      searchForm: {
        startDate: (this.startDate = new Date(new Date().setDate(1))),
        type: '0'
      },
      visible: false,
      displayDetails: [],
      pageNo: 1,
      tableSummary: [],
      tableDetails: [],
      pageSize: 10,
      pageSizes: [10, 25],
      DetailTableCurrentPage: 1,
      typeList: {
        0: this.$t('allRetailAccs.type.all'),
        1: this.$t('allRetailAccs.type.deposit'),
        2: this.$t('allRetailAccs.type.withdrawal')
      }
    };
  },
  watch: {
    transactionListVisible(bool) {
      this.visible = bool;
      if (this.accountNumber != 0 && this.visible) this.getTransactionList();
    },

    visible(bool) {
      if (!bool) {
        this.tableSummary = [];
        this.tableDetails = [];
        this.displayDetails = [];
        this.updateDetailTableData();
      }
      this.disableTransactionListVisible(bool);
    }
  },
  methods: {
    disableTransactionListVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:transactionListVisible', bool);
      });
    },
    getTransactionList() {
      return apiTransactionHistory({
        account: this.accountNumber,
        searchDateStr: this.searchForm.startDate,
        searchType: this.searchForm.type
      }).then(resp => {
        if (resp.data.code === 0) {
          let result = resp.data.data;
          this.tableSummary = [
            {
              currency: result.currency,
              totalDeposit: result.totalDeposit,
              totalWithdraw: result.totalWithdraw,
              netDeposit: result.netDeposit
            }
          ];
          this.tableDetails = resp.data.data.paymentHistoryList;

          this.updateDetailTableData();
        }
      });
    },
    handleDetailTableChange(pageNumber) {
      this.DetailTableCurrentPage = pageNumber;

      this.updateDetailTableData();
    },
    handleDetailTableSizeChange(size) {
      this.pageSize = size;

      this.updateDetailTableData();
    },
    updateDetailTableData() {
      this.displayDetails = this.tableDetails.slice(
        (this.DetailTableCurrentPage - 1) * this.pageSize,

        this.DetailTableCurrentPage * this.pageSize
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
